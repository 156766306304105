<template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{ $t('profile.infotexts.verification-title') }}</h2>
            <p>
              {{ $t('profile.infotexts.verification') }}
            </p>
            
            <hr />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-show="!disableButton" class="account-verifications ">
      <b-col cols="11" offset-md="1">
        <b-row class="account-verifications-buttons">
          <b-col
            cols="4"
            v-for="verification in verifications"
            :key="verification.id"
          >
            <h4>
              {{ verification.name }}
              <img
                v-if="verification.icon != null"
                :src="verification.icon"
                class="ml-3"
                alt=""
              />
            </h4>
          </b-col>
          <b-col cols="4">
            <h4>
              {{ $t('profile.contents.verification.bv') }}
              <img src="/img/verification-icon-cards.svg" class="ml-3" alt="" />
            </h4>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1">
        <h5>{{ $t('profile.contents.verification.status') }}</h5>
      </b-col>
      <b-col cols="11">
        <b-row class="account-verifications-buttons"> 
          <b-col
            cols="4"
            v-for="(item, index) in verifications"
            :key="index"
            class="text-left"
          >
            <h5>
              {{
                ['CURRENT', 'DISABLED'].includes(item.button)
                  ? 'Verified'
                  : 'Not Verfied'
              }}
            </h5>
            <b-button
              class="verification-apply-buttons"
              @click="apply(item.id)"
              v-if="item.button == 'APPLY'"
              :disabled="disableButton"
              variant="primary"
            >
              {{ $t('buttons.apply') }}
              <!-- Verification Yapabilir -->
            </b-button>
            <b-button
              v-if="item.button == 'UPGRADE'"
              variant="primary"
              @click="openUpgradeModal"
              class="verification-apply-buttons"
            >
              {{ $t('buttons.apply') }}
              <!-- Hesabını yükseltmesi lazım ama Apply diyip modal göstereceğiz -->
            </b-button>
            <b-button
              v-if="item.button == 'DISABLED'"
              variant="primary"
              class="verification-apply-buttons"
            >
              {{ $t('profile.contents.verification.active') }}
              <!-- Kullanıcı daha üst bir verification planından doğrulanmış -->
            </b-button>
            <b-button
              v-if="item.button == 'CURRENT'"
              variant="success"
              class="verification-apply-buttons-active"
            >
              {{ $t('profile.contents.verification.active') }}
              <!-- Kullanıcı bu plan ile doğrulama yapmış -->
            </b-button>
          </b-col>
          <b-col
            cols="4"
            class="text-left"
          >
           <h5>
                {{$t("profile.contents.verification.notverified")}}
            </h5>
              <a
             href="mailto:support@connectter.com">
                     
            <b-button variant="primary" class="verification-apply-buttons">
              {{ $t('buttons.contact') }}
              <!-- Business verification -->
            </b-button>
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col cols="12" class="account-sub-header pt-3">
        <loading :loading="loading" />
        <b-button
          v-if="disableButton"
          :disabled="loading"
          variant="primary"
          @click="cancel()"
          class="apply-verification-disable"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <div class="trulioo-form"
          id="trulioo-embedid"
          v-bind:style="{ display: loading ? 'none' : 'block' }"
        ></div>
      </b-col>
    </b-row>
    <b-row v-show="!disableButton">
      <b-col cols="11" offset-md="1" class="account-sub-header pt-3">
        <!-- <h3 class="mb-5">
          This feature is currently in BETA testing and will be available in
          version v.c3.
        </h3> -->
        <p>
          {{ $t('profile.contents.verification.p1') }}
        </p>
        <p>
          {{ $t('profile.contents.verification.p2') }}
        </p>
      </b-col>
    </b-row>
    <sweet-modal class="modal-x" ref="upgrade-plan-modal" icon="warning">
      {{ $t('profile.contents.verification.modal-text') }}
    </sweet-modal>
  </b-container>
</template>

<script>
import VERIFICATION_PLANS from '@/graphql/public/verificationPlans.gql';
import GET_PUBLIC_KEY from '@/graphql/me/profile/getVerificationPublicKey.gql';
import VALIDATE_VERIFICATION from '@/graphql/me/profile/validateVerification.gql';

export default {
  data() {
    return {
      truliooClient: null,
      verifications: [],
      disableButton: false,
      loading: false,
    };
  },
  methods: {
    async getVerifications() {
      try {
        let data = await this.$apollo.query(VERIFICATION_PLANS, {});
        let items = data('**.verificationPlans');
        let mvplan = this.GET_USER_INFO.verification; // my verification plan
        let mplan = this.GET_USER_INFO.plan; // my plan

        for (let item of items) {
          if (
            mvplan == null &&
            _.indexOf(item['settings']['allowed_plans'], mplan.id) !== -1
          ) {
            item.button = 'APPLY';
            continue;
          }

          if (
            mvplan == null &&
            _.indexOf(item['settings']['allowed_plans'], mplan.id) === -1
          ) {
            item.button = 'UPGRADE';
            continue;
          }

          if (mvplan.id == item.id) {
            item.button = 'CURRENT';
            continue;
          }

          if (
            mvplan.level < item.level &&
            _.indexOf(item['settings']['allowed_plans'], mplan.id) !== -1
          ) {
            item.button = 'APPLY';
            continue;
          }

          if (
            mvplan.level < item.level &&
            _.indexOf(item['settings']['allowed_plans'], mplan.id) === -1
          ) {
            item.button = 'UPGRADE';
            continue;
          }

          if (mvplan.level >= item.level) {
            item.button = 'DISABLED';
            continue;
          }
        }

        this.verifications = items;
      } catch (e) {
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },

    async handleResponse(e) {
      try {
        this.loading = true;
        let data = await this.$apollo.mutate(VALIDATE_VERIFICATION, {
          trxid: e.experienceTransactionId,
        });
        let status = data('**.validate');
        if (status === true) {
          let par = {
            token: localStorage.getItem('conToken'),
          };

          await this.$store.dispatch('checkToken', { vm: this, par, refresh: true });
          await this.getVerifications();
          this.disableButton = false;
          document.getElementById('embedid-module').remove();
          return;
        }
       
        this.$bvToast.toast(this.$t("Toaster.not-verified"), {
          title: this.$t("Toaster.info-title"),
          variant: "default",
          autoHideDelay: 1350,
        });
        this.disableButton = false;
        document.getElementById('embedid-module').remove();
      } catch (error) {
        this.disableButton = false;
        return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
      } finally {
          this.loading = false;
      }
    },

    async apply(_id) {
      try {
        let data = await this.$apollo.query(GET_PUBLIC_KEY, { id: _id });
        let publicKey = data('**.publicKey');
        window.onmessage = null;
        if (this.truliooClient) {
          this.truliooClient = null;
        }

        let parent = document.getElementById('trulioo-embedid');
        parent.innerHTML = '';

        let clientParams = {
          publicKey: publicKey,
          accessTokenURL: this.API_DOMAIN,
          handleResponse: this.handleResponse,
        };

        this.truliooClient = new TruliooClient(clientParams);
        this.disableButton = true;
        this.loading = true;
        let id = setInterval(() => {
          let iframe = document.getElementById('embedid-module');
          if (iframe) {
            clearInterval(id);
            iframe.addEventListener('load', () => (this.loading = false), true);
          }
        }, 1);
      } catch (e) {
        this.disableButton = false;
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
      }
    },

    openUpgradeModal() {
      this.$refs['upgrade-plan-modal'].open();
    },

    cancel() {
      this.disableButton = false;
      this.loading = false;
      this.truliooClient = false;
      document.getElementById('trulioo-embedid').innerHTML = '';
      window.onmessage = null;
    },
  },

  mounted() {
    let script = document.createElement('script');
    script.setAttribute('src', 'https://js.trulioo.com/latest/main.js');
    document.head.appendChild(script);
  },

  created() {
    this.getVerifications();
  },
};
</script>
